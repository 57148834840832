// User
export const LOGIN_USER = 'LOGIN_USER'
export const CONTINUE_USER = 'CONTINUE_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const SEND_MESSAGE = 'SEND_MESSAGE'

// Reviews
export const SET_REVIEWS = 'SET_REVIEWS'
export const LOAD_REVIEWS_DETAIL = 'LOAD_REVIEWS_DETAIL'
export const SET_REVIEWS_DETAIL = 'SET_REVIEWS_DETAIL'
export const CREATE_REVIEW = 'CREATE_REVIEW'
