// @flow
import React from 'react'
import { Provider } from 'react-redux'

// Persist
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { configureStore } from './src/store/configureStore'
import * as types from './src/store/types'

const store = configureStore()
const persistor = persistStore(store)

const checkUser = () => {
  const user = store.getState().user || null
  if (!user || user.isLoggedIn) return

  const { expirationDate, loginTime } = user
  if (expirationDate > loginTime) {
    store.dispatch({ type: types.LOGOUT_USER })
  }
}

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }: { element: Node }) => {
  // continueUser()

  checkUser()

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  )
}
