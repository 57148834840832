// @flow

import moment from 'moment'
import * as types from '../types'
import createReducer from '../../lib/createReducer'

const initialState = {
  username: null,
  id: null,
  email: null,
  accessToken: null,
  isLoggedIn: false,
  expiresIn: null,
  expirationDate: null,
  loginTime: null,
}

// refreshToken: null,
// loginDuration: null,
// error: null,
// }

export const user = createReducer(initialState, {
  [types.LOGIN_USER](state, action) {
    const { auth, userData } = action
    const { accessToken, expiresIn } = auth
    const { email, name, id, profileUrl } = userData

    return {
      ...state,
      accessToken,
      isLoggedIn: true,
      expiresIn,
      expirationDate: moment().add(expiresIn, 'seconds').toDate(),
      loginTime: moment().toDate(),
      username: name,
      email,
      profileUrl,
      id,
    }
  },
  [types.CONTINUE_USER](state) {
    // const currentUser = client.getUser()

    // if (clientSDK.loggedIn) {
    //   const { token, localExp } = clientSDK

    //   return Object.assign({}, state, {
    //     // username,
    //     accessToken: token,
    //     // apiUrl: url,
    //     isLoggedIn: true,
    //     expiresIn: localExp,
    //     loginTime: Date.now(),
    //     directusClient: clientSDK,
    //   })
    // }

    return state
    // return updateUserObject(state, action)
  },
  [types.LOGOUT_USER]() {
    return initialState
  },
})
