/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'

// persist
import { persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'

import rootReducer from './reducers'
// import * as types from './types'

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['user'],
}

const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'

const logger = createLogger({
  predicate: () => activeEnv === 'development',
})

const middleware = [thunk]

if (activeEnv === 'development') middleware.push(logger)

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose

const enhancers = composeEnhancers(applyMiddleware(...middleware))

const persistedReducer = persistReducer(persistConfig, rootReducer)
const configureStore = () => createStore(persistedReducer, enhancers)

export { configureStore }
