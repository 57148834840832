// @flow
export default function createReducer(initialState: {} | [], handlers: any) {
  return function reducer(state: {} | [] = initialState, action: Object) {
    /* eslint-disable no-prototype-builtins */
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    }
    return state
  }
}
