/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'typeface-roboto-condensed'
// import 'bootstrap/dist/css/bootstrap-reboot.css'
// import 'bootstrap/dist/css/bootstrap-grid.css'
import './src/config/bootstrap/boostrap.scss'
import moment from 'moment'
import momentDe from 'moment/locale/de'
// import { registerLocale, setDefaultLocale } from 'react-datepicker'
// import de from 'date-fns/locale/de'
import wrapWithProvider from './wrap-with-provider'

moment.updateLocale('de', momentDe)
// registerLocale('de', de)
// setDefaultLocale('de')

export const wrapRootElement = wrapWithProvider
